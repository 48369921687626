const setAnalyticsData = function (data) {
  // Examples of what should be in data:
  // data.screenId = 'game-end-winner-' + winner
  // data.screenChannel = 'game'
  if (data) {
    for (var _name in data) {
      window.dataLayer[_name] = data[_name];
    }
    createAnalyticsEvent();
  }
}

const createAnalyticsEvent = function () {
  var _ev;
  if (typeof Event === 'function') {
    _ev = new Event('screenChange', { bubbles: true, cancelable: false });
  } else {
    _ev = document.createEvent('Event');
    _ev.initEvent('screenChange', true, false);
  }
  window.setTimeout(function () {
    window.dispatchEvent(_ev);
    console.log('AE dispatched');
  }, 250);
}

export default setAnalyticsData