export default function TermsAndConditionsDefault() {
  return (
    <div className="-oneX-terms-and-conditions-body -oneX-body--tertiary">
      <p
        style={{
          textIndent: "0pt",
          textAlign: "center",
          color: "black",
          fontFamily: "Arial, sans-serif",
          fontStyle: "normal",
          fontWeight: "normal",
          textDecoration: "none",
          fontSize: "12pt",
        }}
      >
        State Farm Customer Participant Agreement
      </p>
      <p
        style={{
          textIndent: "0pt",
          textAlign: "center",
          color: "#444",
          fontFamily: "Arial, sans-serif",
          fontStyle: "normal",
          fontWeight: "bold",
          textDecoration: "underline",
          fontSize: "12pt",
        }}
      >
        By confirming your registration you agree to the terms below.
      </p>
      <p
        style={{
          textIndent: "0pt",
          textAlign: "center",
          color: "#444",
          fontFamily: "Arial, sans-serif",
          fontStyle: "normal",
          fontWeight: "bold",
          textDecoration: "underline",
          fontSize: "12pt",
        }}
      >
        Overview of terms:
      </p>

      <ol id="l10">
        <li>
          <p
            style={{
              paddingTop: "4pt",
              paddingLeft: "20pt",
              textIndent: "0pt",
              textAlign: "left",
              color: "#585858",
              fontFamily: "Arial, sans-serif",
              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "none",
              fontSize: "12pt",
            }}
          >
            Ting Sensor and Monitoring Service are provided free. You have no obligation to continue the service and may
            cancel at any time at no cost to you.
          </p>
        </li>
        <li>
          <p
            style={{
              paddingLeft: "20pt",
              textIndent: "0pt",
              textAlign: "left",
              color: "#585858",
              fontFamily: "Arial, sans-serif",
              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "none",
              fontSize: "12pt",
            }}
          >
            Ting should be installed within 10 days of receipt. A smartphone and Wi-Fi are required.
          </p>
        </li>
        <li>
          <p
            style={{
              paddingLeft: "20pt",
              textIndent: "0pt",
              textAlign: "left",
              color: "#585858",
              fontFamily: "Arial, sans-serif",
              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "none",
              fontSize: "12pt",
            }}
          >
            Ting will monitor your home’s electrical system and connected devices to help detect faults that could lead
            to electrical fires.
          </p>
        </li>
        <li>
          <p
            style={{
              paddingLeft: "20pt",
              textIndent: "0pt",
              textAlign: "left",
              color: "#585858",
              fontFamily: "Arial, sans-serif",
              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "none",
              fontSize: "12pt",
            }}
          >
            Ting is a concierge service. Whisker Labs helps coordinate and cover up to $1,000 for any repairs of
            electrical fire hazards detected by Ting.
          </p>
        </li>
      </ol>

      <p
        style={{
          paddingLeft: "16pt",
          textIndent: "0pt",
          textAlign: "left",
          color: "#444",
          fontFamily: "Arial, sans-serif",
          fontStyle: "normal",
          fontWeight: "bold",
          textDecoration: "underline",
          fontSize: "12pt",
        }}
      >
        TING FIRE SAFETY TECHNOLOGY PROGRAM
        <span
          style={{
            color: "#444",
            fontFamily: "Arial, sans-serif",
            fontStyle: "normal",
            fontWeight: "bold",
            textDecoration: "none",
            fontSize: "12pt",
          }}
        >
          _
        </span>
        PARTICIPATION AGREEMENT
      </p>

      <p style={{ paddingTop: "4pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify", fontSize: "11pt" }}>
        This is an Agreement (“Agreement”) between the <span style={{ color: "#000" }}>State Farm Company</span> named
        below (“State Farm Company”) and its Homeowners Policyholder, (“You”) with the Policyholder home address(es)
        that you have entered or selected (“Home”) to govern your participation in the Ting Fire Safety Technology
        Program (“Program”). The State Farm Company is determined based upon the location of your Home and will change
        if you change the location of your Home.
      </p>

      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
          fontWeight: "bold",
          color: "black",
          fontSize: "11pt",
          backgroundColor: "yellow",
        }}
      >
        If your Home is in California, the State Farm Company is State Farm General Insurance Company.
      </p>

      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
          fontWeight: "bold",
          color: "black",
          fontSize: "11pt",
          backgroundColor: "yellow",
        }}
      >
        If your Home is in Florida, the State Farm Company is State Farm Florida Insurance Company.
      </p>

      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
          fontWeight: "bold",
          color: "black",
          fontSize: "11pt",
          backgroundColor: "yellow",
        }}
      >
        If your Home is in Texas, the State Farm Company is State Farm Lloyds.
      </p>

      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
          fontWeight: "bold",
          color: "black",
          fontSize: "11pt",
          backgroundColor: "yellow",
        }}
      >
        For all other states, the State Farm Company is State Farm Fire and Casualty Company
      </p>

      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
          fontWeight: "bold",
          color: "black",
          fontSize: "11pt",
        }}
      >
        THIS AGREEMENT SETS FORTH THE TERMS OF OUR AGREEMENT AND CONTAINS LIMITED WARRANTIES, DISCLAIMER OF WARRANTIES
        AND LIMITATIONS OF LIABILITY AND SHOULD BE READ CAREFULLY. THIS AGREEMENT IS NOT AN INSURANCE CONTRACT AND DOES
        NOT MODIFY OR CHANGE ANY TERM, CONDITION, OR COVERAGE WITHIN YOUR INSURANCE POLICY
      </p>

      <ol id="l9">
        <li>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            You agree to allow State Farm to provide Whisker Labs, the manufacturer of the Ting Fire Safety Technology,
            your name, email address, installation address, shipping address, phone number, and information pertaining
            to your insurance products and claims experiences with State Farm for the purposes of:
          </p>
          <ul id="l10" style={{ paddingBottom: "1em" }}>
            <li>
              <p style={{ margin: "0" }}>Program registration</p>
            </li>
            <li>
              <p style={{ margin: "0" }}>Ting Sensor shipment and delivery</p>
            </li>
            <li>
              <p style={{ margin: "0" }}>
                Delivery of emails regarding program information and correspondence about Ting sensor shipment, delivery
                and installation, and delivery of SMS messages (Your cell carrier Message & Data rates may apply), if
                You consent to receive such messages
              </p>
            </li>
            <li>
              <p style={{ margin: "0" }}>Registration of your Ting service account</p>
            </li>
            <li>
              <p style={{ margin: "0" }}>Contacting You in the case of fire hazard detection at your home</p>
            </li>
            <li>
              <p style={{ margin: "0" }}>Improving detection of precursors to electrical fires</p>
            </li>
          </ul>
          <p
            style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify", fontWeight: "bold", color: "black" }}
          >
            If your Home is in California, you authorize State Farm Company to send your name and confirmation of your
            status as an insured of State Farm to Whisker Labs Inc. to confirm your eligibility. This consent will
            remain in effect until revoked or modified by you. You may revoke your consent at any time by contacting
            State Farm.
          </p>
        </li>
        <li>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            <span>
              Whisker Labs will provide You with one Ting sensor, to be self-installed by You in your eligible Home
              within 10 days of receipt of the Ting sensor, using the Ting Sensor mobile application and instructions
              provided by Whisker Labs. If your circumstances necessitate time beyond 10 days, You agree to advise
              Whisker Labs so their communications with you can be adjusted as needed. Should you elect not to install
              your Ting Sensor you are required to return it to Whisker Labs by contacting them for instructions at{" "}
            </span>
            <a className="-oneX-link--inline" href="mailto:help@whiskerlabs.com" target="_blank" rel="noreferrer">
              help@whiskerlabs.com.
            </a>
          </p>
        </li>
        <li>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            You will agree to the Whisker Labs Terms and Conditions at time of installation, which can be found at{" "}
            <a
              className="-oneX-link--inline"
              href="https://www.tingfire.com/ting-terms-of-service/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.tingfire.com/ting-terms-of-service/{" "}
            </a>
          </p>
        </li>
        <li>
          <p style={{ paddingTop: "4pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            State Farm Company agrees to pay for the cost of the initial activation, Ting sensor, and the Monitoring
            Service. State Farm Company, at any time and at its sole option, may end its payment of the Monitoring
            Service and provide notice of discontinuation of payment and allow You to continue in the Program by paying
            the then-current rates, fees, and taxes.
          </p>
          <p style={{ paddingTop: "4pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            The Ting sensor, using high speed digital signal processing and machine learning, is intended to detect the
            presence of small electrical discharges and arcs prior to them potentially becoming an imminent fire hazard
            within the home. The sensor, activation and service are being provided free of charge in exchange for your
            participation in the Program, your associated responsibilities defined herein, and for being a current and
            continuous Homeowners Policyholder (non-tenant) with State Farm Company
          </p>
        </li>
        <li>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            You agree to allow State Farm Mutual Automobile Insurance Company, for the benefit of itself, its
            subsidiaries and affiliates, including State Farm Company (collectively “State Farm”) access to your Ting
            sensor events data, which will be made available to State Farm during the Program. State Farm may access and
            transfer the data to a secure environment. State Farm may analyze the data to learn more about the Whisker
            Labs Ting Fire Safety technology capabilities, and detection efficiency.State Farm may also use the data for
            purposes of insurance underwriting, pricing, claims handling, and other insurance needs.
          </p>
        </li>
        <li>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            If at the conclusion of the Program, You wish to keep the Ting sensor and continue with Whisker Labs
            service, You will have the opportunity to become a direct customer of Whisker Labs and pay the then-current
            rates, fees, and taxes. You are under no obligation to continue using the Sensor or Monitoring Service. This
            relationship would be solely between You and Whisker Labs, independent of any State Farm Company
            affiliation. State Farm Company makes no representation regarding the tax implications of participating in
            this Program. You should consult a professional tax advisor regarding your personal situation.
          </p>
        </li>
        <li>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            State Farm makes no representations or warranties regarding the performance of the Whisker Labs Ting sensor
            or associated Monitoring Service (“System”). You agree that State Farm is not responsible for the
            performance of the Ting sensor or Monitoring Service. The Whisker Labs Ting Sensor and Monitoring Service
            may not recognize all conditions, surges, sags, dips, faults, overload, or properly detect arc faults, which
            represent an imminent fire hazard.
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            You understand and agree that the service rendered under this Program relies on wireless or broadband
            (Internet access) and local area network (Wi-Fi) both of which are under your control and State Farm does
            not control or guarantee any wireless or broadband service or local area network. Inherent in this system is
            a failure when wireless or broadband (Internet) and/or local area network has an interruption in service,
            including at your Home. In this event, because it is also dependent on internet access, the Monitoring
            Service will likewise be interrupted. State Farm is not responsible for any action taken, or not taken, by
            You as a result of this Program.
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", fontWeight: "bold" }}>
            NO WARRANTY OR REPRESENTATION. State Farm does not represent or warrant that the Whisker Labs Ting sensor or
            Monitoring Service (“System”) may not be compromised or circumvented or that the System will prevent any
            personal injury, loss of life, or property loss or damage or that the System will in all cases provide the
            notification or monitoring which is intended regardless of the reason, including but not limited to a
            failure in the monitoring or failure to contact You, and You have not relied upon any such representation or
            warranty. You acknowledge that any other affirmation of fact or promise made by State Farm, even if in
            writing shall not be deemed to create an express warranty. STATE FARM, ON BEHALF OF ITSELF AND WHISKER LABS,
            EXPRESSLY DISCLAIMS ANY AND ALL OTHER REPRESENTATIONS AND WARRANTIES WHETHER EXPRESS OR IMPLIED, INCLUDING
            THOSE OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. STATE FARM AND ITS
            DIRECT AND INDIRECT SUPPLIERS MAKE NO REPRESENTATION THAT THE SYSTEM WILL BE UNINTERRUPTED OR ERROR-FREE OR
            THAT THE SYSTEM WILL ALWAYS FUNCTION AS INTENDED.
          </p>
        </li>
        <li>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            State Farm Company may terminate the Program at any time upon notice to You. You may discontinue
            participation in the Program at any time by providing notice to Whisker Labs. Once your discontinuation
            notice is received or if State Farm Company terminates, State Farm Company will stop collecting data, but
            State Farm will retain and use the data collected prior to your discontinuation.
          </p>
        </li>
        <li>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            You acknowledge that because You are not being charged by State Farm Company for the Monitoring Service and
            your control over your Wi-Fi and Internet access are not sufficient to guarantee that no loss or damage will
            occur or that the System will always function as intended. State Farm does not assume responsibility for any
            personal injury, loss of life, or property loss or damage which may occur even if due to State Farm’s
            negligent performance or which may arise due to the faulty operation of the System, the failure to perform
            said service, or the failure of the Monitoring Service.
          </p>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            State Farm Company and You hereby agree that if, notwithstanding the above provisions, there should arise
            any liability whatsoever on the part of State Farm, its directors, officers, employees, agents, or assigns,
            it is agreed that the total liability shall not exceed the aggregate sum of two hundred fifty dollars
            ($250). This sum shall be complete and exclusive and shall be paid and received as an exclusive remedy and
            not as a penalty.
          </p>
        </li>
        <li>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            Any use of data by State Farm collected from the Program or your participation therein will be in accordance with the
            Notice of Privacy Policy of State Farm available at{" "}
            <a
              className="-oneX-link--inline"
              href="https://www.statefarm.com/customer-care/privacy-security/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Notice of Privacy Policy | State Farm{" "}
            </a>
          </p>
        </li>
        <li>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            State Farm may, at any time and for any reason without notice to you, update the terms of this Participant
            Agreement. Such revisions will be effective immediately, and your continued use of the System and
            participation in the Program after any revisions constitutes your acceptance of the revised terms. If you do
            not agree to the new terms, you may discontinue your participation in the Program in accordance with section
            8 herein.
          </p>
        </li>
        <li>
          <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
            You may not assign this Agreement, or your rights or responsibilities hereunder, to any other person of
            entity, unless such assignment is approved in writing by State Farm.
          </p>
        </li>
      </ol>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "justify",
          color: "black",
          fontSize: "8pt",
        }}
      >
        Ting {process.env.REACT_APP_TING_PARTICIPANT_AGREEMENT_VERSION}
      </p>
    </div>
  )
}
