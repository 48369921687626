
import logger, {getSpaRequestId} from '../utils/logger.js';
import sleeper from '../utils/sleep-promise.js';
import { refreshOktaToken, getOktaToken } from '../utils/session-management.js';

const spaRequestId = getSpaRequestId()

export async function getResidences(tries = 1) {
  let authToken;
  // Prioritize Okta
  if (await refreshOktaToken()) { // Refresh the token right before sending it downstream
    authToken = "Bearer " + getOktaToken();
  } else {
    throw new Error("Okta token refresh failed");
  }
  
  logger("PA2 getResidences started");
  try {
    const res = await fetch(
      `${process.env.REACT_APP_TING_API_URL}/pa/residences`,
      {
        headers: {
          "Content-Type": "application/json",
          "authorizationToken": authToken,
          "x-sf-ting-spaRequestId": spaRequestId
        },
        method: 'GET'
      }
    ).catch(error => {
      logger("PA2 getResidences error:", error);
      res.status(500).json({ response: "Something Went Wrong." })
      res.end()
    });

    if (res.status !== 200 && res.status !== 401 && res.status !== 403
        && tries < process.env.REACT_APP_MAX_GET_RESIDENCIES_TRIES
    ) {
      // Wait time to try again gets incrementally longer
      await sleeper(tries * process.env.REACT_APP_GET_RESIDENCES_RETRY_WAIT_INTERVAL);
      // Recursively call getResidences() up until REACT_APP_MAX_GET_RESIDENCIES_TRIES when there's an error
      logger(`PA2 getResidences 5xx error retry #${tries}`, {status: res.status});
      return getResidences(tries + 1);
    }
    logger("PA2 getResidences status", res.status);
    return res;
  } catch (error) {
    if (tries < process.env.REACT_APP_MAX_GET_RESIDENCIES_TRIES) {
      // Wait time to try again gets incrementally longer
      await sleeper(tries * process.env.REACT_APP_GET_RESIDENCES_RETRY_WAIT_INTERVAL);
      // Recursively call getResidences() up until REACT_APP_MAX_GET_RESIDENCIES_TRIES when there's an error
      logger(`PA2 getResidences error retry #${tries}`, {error});
      return getResidences(tries + 1);
    }
    return {
      timedOut: true
    };
  }
}