import { useEffect } from 'react';

export default function FullScreenSpinner() {
  useEffect(() => {
  }, []);

  return (
    <div id="spinner-container" style={{backgroundColor: 'rgba(241,241,241,0.73)', width: '100%', height: '100%', zIndex: '3000', top: '0', position: 'fixed'}}>
      <div className="spinner-border" style={{width: '10rem', height: '10rem', backgroundColor: 'transparent', left: '50%', marginLeft: '-5rem', zIndex: '10', top: '50%', marginTop: '-5rem', position: 'fixed'}} role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}
