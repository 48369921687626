export const toggleErrorAlert = () => {
  if (document.getElementById('errorAlert').classList.contains("d-none")) {
    document.getElementById('errorAlert').classList.toggle('d-none')
    window.scrollTo(0, 0);
  }   
}

export const toggleErrorAlreadyEnrolledAlert = () => {
  if (document.getElementById('errorAlreadyEnrolledAlert').classList.contains("d-none")) {
    document.getElementById('errorAlreadyEnrolledAlert').classList.toggle('d-none')
    window.scrollTo(0, 0);
  }
}