import { parseJwt, exchangeOauth2Tokens } from "customer-oauth2-token-management-utility";
import Cookies from 'js-cookie';

// This function will use the Okta OAuth2.0 cookie `sf-cauth1`
// and the Okta refresh cookie `sf-cauth2` to refresh the user's
// Okta token session.
//
// A return value of `true` indicates that the Okta token was
// successfully refreshed.
// A return value of `false` indicates
// that the Okta token was not successfully refreshed and that the
// user should be redirected to Proofing's logout page to clear
// their Okta cookies.
export async function refreshOktaToken() {
  let success = false

  try {
    success = await exchangeOauth2Tokens();
    console.log("after exchanged oauth2 tokens")
    console.log(`success = ${success}`)
  } catch (e) {
    console.log("error in refreshOktaToken")
    console.log(e);
    // Eating the exception to allow processing to continue
    // and return the default of `false`
  }

  return success
}

export function getOktaToken() {
  return Cookies.get('sf-cauth1');
}

export function getSSOToken() {
  return Cookies.get('sf-icp-sso');
}

export function isOktaTokenAvailable() {
  let oktaToken = getOktaToken()
  return oktaToken !== undefined && oktaToken.length > 0
}

export function isSSOTokenAvailable() {
  let ssoToken = getSSOToken()
  return ssoToken !== undefined && ssoToken.length > 0
}

// This function will use the Okta OAuth2.0 cookie `sf-cauth1`
// and the `parseJwt` utility to determine if the expiration
// time of the Okta token is within a specified bound (in seconds).
//
// A return value of `true` indicates that the Okta token is not going
// to expire within `expirationBufferInSeconds`
// A return value of `false` indicates that the Okta token has either
// expired or will expire within the given buffer.
export function oktaTokenExpiresBefore(expirationBufferInSeconds = process.env.REACT_APP_OKTA_TOKEN_EXPIRATION_BUFFER_SECONDS) {
  let tokenExpiresBeforeBufferTime = true

  // Convert expirationBufferInSeconds to an Integer
  try {
    expirationBufferInSeconds = parseInt(expirationBufferInSeconds)
  } catch(e) {
    console.log(`expirationBufferInSeconds not an Integer`)
    expirationBufferInSeconds = 300
  }

  try {
    const oktaToken = getOktaToken()
    const oktaTokenPayload = parseJwt(oktaToken);
    const oktaTokenExpiration = oktaTokenPayload.exp;
    const currentTime = Math.floor(Date.now() / 1000);

    if (oktaTokenExpiration - currentTime > expirationBufferInSeconds) {
      tokenExpiresBeforeBufferTime = false;
    }
  } catch (e) {
    console.log("error in oktaTokenExpiresBefore")
    console.log(e);
    // Eating the exception to allow processing to continue
    // and return the default of `true`
  }

  return tokenExpiresBeforeBufferTime
}


// Note: Removing a nonexistent cookie neither raises any exception nor returns any value.
export function removeAuthCookies() {
  Cookies.remove('sf-cauth1', { path: '', domain: '.statefarm.com' });
  Cookies.remove('sf-cauth2', { path: '', domain: '.statefarm.com' });
  Cookies.remove('sf-icp-sso', { path: '', domain: '.statefarm.com' });
}

// Using the Okta and token, determine if the user's session is valid.
export async function refreshSession() {
  let validSession = false
  let goodOktaToken = false
  // Always attempt to use the Okta token first.

  // Validate that Okta session has not expired, and extend it if possible.
  if (!oktaTokenExpiresBefore(process.env.REACT_APP_OKTA_TOKEN_EXPIRATION_BUFFER_SECONDS)) {
    goodOktaToken = true
  } else {
    goodOktaToken = await refreshOktaToken()
  }

  // If the Okta token is "good",
  // then we consider the session valid
  validSession = goodOktaToken

  return validSession
}