import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import logger from '../utils/logger.js';
import setAnalyticsData from '../utils/adobe.js';
import ChatBot from '../components/Chatbot';

export default function EnrollmentSuccess() {
  logger('Success page')

  let { state } = useLocation()

  useEffect(() => {
    if (typeof dataLayer === "undefined") {
      window.dataLayer = {
      adobe_link_name: "",
      adobe_button_name: "",
      adobe_radio_name: "",
      adobe_checkbox_name: ""
      }
    }

    var preloadedScript = document.createElement("script");
    preloadedScript.src = process.env.REACT_APP_ONEX_URL + "/en_US/dxl-1x/prod/js/1x.core.js";
    document.body.appendChild(preloadedScript);

    state = state || {}
    setAnalyticsData({
      screenId: 'ting-enrollment-success',
      screenChannel: 'ting',
      clientID: state.customerId || "",
      agreementIndex: state.selectedAgreementId || ""
    })

  }, []);

  function clickEnrollAgain() {
  }

  return (
    <div className="main-container">
      <head>
        <title>Ting Enrollment - Success</title>
        <link rel="icon" href="/favicon.ico" />

        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossOrigin="anonymous"/>
        <link rel="stylesheet" href={process.env.REACT_APP_ONEX_URL + "/en_US/dxl-1x/prod/css/lib/normalize.css"}/>
        <link rel="stylesheet" href={process.env.REACT_APP_ONEX_URL + "/en_US/dxl-1x/prod/css/1x.core.css"} type="text/css"/>
      </head>

      <header data-type="1x-header-auth"></header>

      <main className="main">
        <div className="-oneX-container">
          <div className="-oneX-row pb-5">
            <div className="-oneX-col-12">
              <h1>Congratulations!</h1>
              <p>Your enrollment to receive Ting free of charge has been completed! We are excited to have you join the Ting fire safety community!</p>
              <p>It only takes 2 minutes to install the sensor, which will activate your Ting service. We look forward to seeing your Ting sensor come online shortly after it arrives at your home.</p>
              <p>If you have no additional policies to enroll, you can close this page and navigate to your email for a confirmation from Whisker Labs that you should receive shortly.</p>
              <p>You can also return to the <a href={process.env.REACT_APP_TING_FIRE_SAFETY} style={{textDecoration: 'underline'}} onClick={clickEnrollAgain.bind(this)} className="-oneX-link--inline">State Farm Ting information page</a> to review the eligibility requirements and enroll additional eligible policies in the Ting offer.</p>
            </div>
          </div>
          <ChatBot/>
        </div>
      </main>

       <footer data-type="1x-footer"></footer>
       <script rel="preload" src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossOrigin="anonymous"></script>
       <script rel="preload" src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossOrigin="anonymous"></script>
    </div>
  )
}
