import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import setAnalyticsData from '../../utils/adobe.js';
import ChatBot from '../../components/Chatbot.js';
import CustomButton from '../../components/CustomButton.js';
import SupportEmailLink from '../../components/SupportEmail.js';

function TechnicalError() {

  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const err = query.get('err')
  const cd = query.get('cd')
  const [supportEmailSubject, setSupportEmailSubject] = useState("SF Ting Enrollment Error")
  const tingContentLink = process.env.REACT_APP_TING_FIRE_SAFETY


  useEffect(() => {
    // Load 1x Script
    var oneXScript = document.createElement("script");
    oneXScript.src = process.env.REACT_APP_ONEX_URL + "/en_US/dxl-1x/prod/js/1x.core.js";
    document.body.appendChild(oneXScript);

    setAnalyticsData({
      screenId: 'ting-enrollment-error',
      screenChannel: 'ting'
    })

    if (err && cd) {
      setSupportEmailSubject(supportEmailSubject + `(${err}:${cd})`)
    } else if (err) {
      setSupportEmailSubject(supportEmailSubject + `(${err})`)
    } else if (cd) {
      setSupportEmailSubject(supportEmailSubject + `(${cd})`)
    }
  }, []);

  const handleNavigation = ( event ) => {
    event.preventDefault();
    window.location.href = tingContentLink;
  }

  return (
    <div className="main-container">
      <head>
        <title>Ting Enrollment - Error</title>

        <link rel="preload stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossOrigin="anonymous"/>
        <link rel="preload stylesheet" href={process.env.REACT_APP_ONEX_URL + "/en_US/dxl-1x/prod/css/lib/normalize.css"}/>
        <link rel="preload stylesheet" href={process.env.REACT_APP_ONEX_URL + "/en_US/dxl-1x/prod/css/1x.core.css"} type="text/css"/>

        {/* <script src={process.env.REACT_APP_ADOBE_ANALYTICS} async></script> */}
      </head>

      <header data-type="1x-header-auth"></header>

      <main className="main">
        <div className="-oneX-container">
          <div className="-oneX-row pb-5">
            <div className="-oneX-col-12">
            <h1 className='-oneX-typography-variant4'>We apologize but something went wrong.</h1>
              <p>Please attempt to 
                <a href={tingContentLink} className="-oneX-link--inline" onClick={handleNavigation}> enroll </a>
                 again after completing each of the following troubleshooting steps:
                <div>
                <br />
                <li>Use your browser&apos;s incognito/private mode</li>
                <li>Upgrade your browser to the most current version</li>
                <li>Use a different device or browser</li>
                </div>
              </p>
              <p>
                If you still receive an error, please contact the <SupportEmailLink subject={supportEmailSubject}/> for assistance. 
              </p>
            </div>
              <div className="-oneX-row">
              <div className="-oneX-col-12 d-flex justify-content-center pt-4">
                <CustomButton id="navButton" type="button" onClick={handleNavigation}>Ting home</CustomButton>
              </div>
            </div>
          </div>
          <ChatBot/>
        </div>
      </main>

       <footer data-type="1x-footer"></footer>
       <script rel="preload" src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossOrigin="anonymous"></script>
       <script rel="preload" src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossOrigin="anonymous"></script>
    </div>
  )
}

export default TechnicalError;