import { React } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Ineligible from './Ineligible'
import Success from './Success'
import Error from './error/Index'
import TechnicalError from './error/Technical'
import TechnicalSlugError from './error/TechnicalSlug'
import PAAcceptance from './pa/Acceptance';
import PAIneligible from './pa/Ineligible';
import PASuccess from './pa/Success';
import PAError from './pa/Error';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/ineligible" element={<Ineligible/>} />
        <Route path="/success" element={<Success/>} />
        <Route path="/error" element={<Error/>} />
        <Route path="/error/technical" element={<TechnicalError/>} />
        <Route path="/error/:slug" element={<TechnicalSlugError/>} />
        <Route path="/pa/acceptance" element={<PAAcceptance/>} />
        <Route path="/pa/ineligible" element={<PAIneligible/>} />
        <Route path="/pa/success" element={<PASuccess/>} />
        <Route path="/pa/error" element={<PAError/>} />
        <Route path="*" element={<Home/>} />
      </Routes>
    </Router>
  );
}

export default App;